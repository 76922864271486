import {mapActions, mapGetters} from "vuex";

export default {
    computed:{
        ...mapGetters({
            registryList:'orders/registryList',
        })
    },
    created() {
        this.getRegistrylist();
    },
    methods:{
        closePopup(){
            this.$emit('closePopup')
        },
        createNewRegister(){
            this.$emit('createNewRegister')
            this.$emit('closePopup')
        },
        addToRegister(){
            this.$emit('addToRegister')
            this.$emit('closePopup')
        },
        ...mapActions({
            getRegistrylist:'orders/getRegistrylist',
        })
    }
}